<template>
  <div>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1><b-icon icon="sun" /> Turnos de {{ this.name }}</h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toPrograms"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container">
            <b-row>
              <b-col><TurnsRegister :id="identifier" /></b-col>
              <b-col class="mb-3">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="keyword"
                    placeholder="Buscar"
                    type="text"
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </div>
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(state)="row">
                  <b-badge pill :variant="returnColor(row.item.state)">{{
                    returnData(row.item.state)
                  }}</b-badge>
                </template>
                <template #cell(actions)="row">
                  <b-icon-trash
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="modalDelete(row.item)"
                  ></b-icon-trash>
                </template>
              </b-table>
              <b-pagination
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Mensaje alerta eliminacion-->

    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.name }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import TurnsRegister from "./Turns-register.vue";
export default {
  components: { TurnsRegister },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    keyword: "",
    perPage: 10,
    currentPage: 1,
    dataDelete: "",
    showDelete: false,
    identifier: null,
    name: "",
    data: {},
    fields: [
      { key: "name", label: "Nombre" },
      { key: "capacity", label: "Capacidad" },
      { key: "state", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.capacity
                .toString()
                .toLowerCase()
                .includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    toPrograms() {
      this.$router.push("/configuration/program");
    },
    toCourses(data) {
      this.$router.push("/modules/" + data + "/courses");
    },
    returnData(data) {
      if (data === "A") return "Activo";
      else if (data === "I") return "Inactivo";
    },
    returnColor(data) {
      if (data === "A") return "success";
      else if (data === "I") return "danger";
    },
    exit() {
      this.show = false;
    },
    exitDelete() {
      this.showDelete = false;
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.name = data.name;
    },
    async initialize() {
      await this.$axios
        .get("/configuration/program/" + this.id + "/group", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.identifier = res.data.id;
          this.name = res.data.name;
          this.desserts = res.data.list;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get(
              "/configuration/program/program-group/group-delete?programId=" +
                this.identifier +
                "&groupId=" +
                item.id,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              }
            )
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error(e.response.data.content);
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>